export const ptBrTranslations = {
  translations: {
    banner_subtitle: 'Desde 2012 desenvolvendo soluções inteligentes para a indústria',
    qualified_partner: 'Parceiro Qualificado B&R',
    message: 'Olá! Tenho interesse em receber mais informações sobre os serviços e produtos da Puchta Engenharia!',
    alt: {
      logo: 'Logotipo da Puchta Engenharia',
      language: 'Bandeira do idioma selecionado',
      flags: {
        pt_BR: 'Bandeira do Brasil',
        en_US: 'Bandeira dos Estados Unidos',
        es: 'Bandeira da Espanha'
      }
    },
    customers: {
      title: 'Nossos clientes'
    },
    partners: {
      title: 'Nossos parceiros'
    },
    menu: {
      home: 'Início',
      products: 'Produtos',
      services: 'Serviços',
      contact: 'Contato',
      solutions: 'Soluções',
      customers: 'Clientes',
      partners: 'Parceiros'
    },
    language: {
      portuguese: 'Português',
      spanish: 'Espanhol',
      english: 'Inglês',
      change: 'Selecione seu idioma'
    },
    footer: {
      copyright: 'Puchta Engenharia. Todos os direitos reservados',
      address: {
        title: 'Endereço',
        first_part: 'Rua dos Topázios, nº 135, Centro',
        second_part: 'Sala 21, Carambeí-PR'
      },
      opening_hours: {
        title: 'Horário de Atendimento',
        first_part: 'Segunda à Sexta',
        second_part: 'Das 7:30h às 17:30h'
      },
      institutional: {
        title: 'Institucional'
      },
      contact: {
        title: 'Contato',
        phone_number: 'Telefone:',
        email: 'Email:'
      }
    },
    contact: {
      title: 'Entre em contato conosco!',
      phone_number: 'Telefone:',
      email: 'Email:',
      form: {
        name: {
          label: 'Nome',
          placeholder: 'Seu nome',
          warning: 'Insira seu nome!'
        },
        email: {
          label: 'Email',
          placeholder: 'Seu email',
          warning: 'Insira um email válido!'
        },
        msg: {
          label: 'Mensagem',
          placeholder: 'Como podemos ajudar?',
          warning: 'Insira sua mensagem!'
        },
        submit: 'Enviar'
      },
      notification: {
        success: 'Sua mensagem foi enviada com sucesso!',
        error: 'Houve algum problema ao enviar sua mensagem'
      }
    },
    who_we_are: {
      title: 'Quem somos?',
      text: 'A Puchta Engenharia é uma empresa de engenharia e automação industrial que atua no mercado com foco na qualidade dos serviços ofertados ao cliente, tendo como pilar principal a empatia para com os clientes, bem como oferecer um produto de alta qualidade técnica. Hoje, contamos com uma equipe altamente qualificada para desenvolver e executar nossos serviços. Estamos há onze anos no mercado desenvolvendo soluções inteligentes para a indústria sempre focando em nossos pilares: excelência, responsabilidade e empatia para com o cliente.'
    },
    services: {
      title: 'Soluções',
      card_1: {
        title: 'Mwise 2.0',
        text: 'O Mwise é um sistema especialista em gestão de máquinas e linhas produtivas que permite o gerenciamento de dados para colocar você no controle.',
        link: 'Saiba mais'
      },
      card_2: {
        title: 'Desenvolvimento de Projetos de Automação Industrial',
        text: 'Do projeto à execução final, a Puchta Engenharia possui profissionais qualificados e oferece as mais altas tecnologias do mercado.',
        link: 'Saiba mais'
      }
    },
    industrial_automation: {
      title: 'Desenvolvimento de Projetos de Automação Industrial',
      text: 'Do projeto à execução final, a Puchta Engenharia possui profissionais qualificados e oferece as mais altas tecnologias disponíveis no mercado. Cuidamos de questões específicas ou projetos completos; atendemos às necessidades integrais de sua empresa em todos os níveis de produção.',
      ul: {
        li_1: 'Consultoria para elaboração e formulação do produto',
        li_2: 'Especificação de componentes',
        li_3: 'Desenvolvimento',
        li_4: 'Execução',
        li_5: 'Treinamento para as áreas envolvidas'
      }
    },
    mwise: {
      title: 'Mwise 2.0',
      first_paragraph:
        'O Mwise é um sistema especialista em gestão de máquinas e linhas produtivas, que permite o gerenciamento de dados para colocar você no controle.',
      second_paragraph:
        'Desenvolvido em ambiente web-based, 100% online, o que permite a sua utilização em todas as plataformas: computadores, tablets, smartphones. Tudo com máxima segurança, rodando em servidor local ou em nuvem.',
      third_paragraph:
        'Além disso, o MWISE foi desenvolvido fazendo o uso da tecnologia de módulos de gestão, abrangendo: Line Monitoring System (LMS), Product Lifecycle Management (PLM), Business Intelligence (BI) e de Rastreabilidade de Processo.',
      fourth_paragraph:
        'O sistema permite a você monitorar os parâmetros de processo e os pontos de controle mais importantes de duas máquinas e linhas de produção.',
      fifth_paragraph: 'Gerencie os dados de sua linha e obtenha os seguintes benefícios:',
      ul: {
        li_1: 'Reduzir os erros humanos com decisões baseadas em fatos;',
        li_2: 'Garantir procedimentos operacionais consistentes com informações confiáveis e gerenciamento de dados;',
        li_3: 'Reduzir a mão de obra necessária: menos tempo necessário para cálculos e análise de dados;',
        li_4: 'Reduzir desperdício e produtos fora das especificações;',
        li_5: 'Aumentar OEE (Eficiência Geral dos Equipamentos) com utilização otimizada de linhas e equipamentos.'
      }
    }
  }
};
