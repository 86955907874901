import Translator from '../Translator';
import CustomCarousel from '../CustomCarousel';

import { customers } from '../../constants';

import { Title, TitleBorderBottom, TitleBorderBottomContainer, Logo } from './style';

export default function Customers() {
  return (
    <>
      <Title id="customers">
        <h2>
          <Translator path={'customers.title'} />
        </h2>
      </Title>

      <TitleBorderBottomContainer>
        <TitleBorderBottom />
      </TitleBorderBottomContainer>

      <CustomCarousel>
        {customers.map((customer, index) => (
          <Logo key={index} src={customer.logo} height={customer.height} />
        ))}
      </CustomCarousel>
    </>
  );
}
