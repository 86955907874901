import styled from 'styled-components';

import { HashLink } from 'react-router-hash-link';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: 1399px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const LinkContainer = styled(HashLink)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:link {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:hover {
    color: inherit;
  }

  &:active {
    color: inherit;
  }

  @media (max-width: 1400px) {
    justify-content: start;
    border-radius: 5px;

    &:hover {
      background-color: ${(props) => props.theme.button_hover_background};
    }

    &:active {
      background-color: ${(props) => props.theme.button_active_background};
    }
  }

  @media (min-width: 1400px) {
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: 2px;
      background-color: ${(props) => props.theme.colors.primary};
      transform: scaleX(0);
      transition: transform 0.25s ease-in-out;
    }

    &:hover::before {
      transform: scaleX(1);
    }
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.colors.primary};
`;
