import { useTranslation } from 'react-i18next';

import { Radio } from 'antd';

import { languages } from '../../configs/languages';

import { CustomRadioGroup, RadioGroupContainer, RadioContainer, Flag, Language } from './style';

export default function LanguageSelector({ handleClose }) {
  const { i18n, t } = useTranslation();

  const selectedLanguage = localStorage.getItem('i18nextLng');

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    handleClose();
  };

  const adjustAbbreviation = (abbreviation) => {
    return abbreviation.replace('-', '_');
  };

  return (
    <CustomRadioGroup onChange={changeLanguage} value={selectedLanguage}>
      <RadioGroupContainer>
        {languages.map((language) => (
          <Radio key={language.abbreviation} value={language.abbreviation}>
            <RadioContainer>
              <Flag src={language.flag} alt={t(`alt.flags.${adjustAbbreviation(language.abbreviation)}`)} />
              <Language>{language.name}</Language>
            </RadioContainer>
          </Radio>
        ))}
      </RadioGroupContainer>
    </CustomRadioGroup>
  );
}
