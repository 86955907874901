import { useSelector, useDispatch } from 'react-redux';
import { darkTheme, lightTheme } from '../../store/slices/theme';

import { DarkThemeIcon, LightThemeIcon, ThemeSwitcherButton } from './style';

export default function ThemeSwitcher() {
  const dispatch = useDispatch();

  const type = useSelector((state) => state.theme?.type);

  const handleChangeTheme = (theme) => {
    if (theme === 'light') dispatch(darkTheme());
    if (theme === 'dark') dispatch(lightTheme());
  };

  return (
    <ThemeSwitcherButton onClick={() => (type === 'light' ? handleChangeTheme('light') : handleChangeTheme('dark'))}>
      {type === 'light' ? <LightThemeIcon size={24} /> : <DarkThemeIcon size={24} />}
    </ThemeSwitcherButton>
  );
}
