import { useState } from 'react';

import { Spin } from 'antd';

import Layout from '../../layout';
import Divider from '../../components/Divider';
import Services from '../../components/Services';
import Partners from '../../components/Partners';
import Customers from '../../components/Customers';
import Translator from '../../components/Translator';

import video from '../../assets/background.mp4';
import image from '../../assets/Industry-4.0.png';

import {
  Banner,
  Video,
  Content,
  TextContainer,
  Title,
  AboutUsContainer,
  TitleBorderBottomContainer,
  TitleBorderBottom,
  Paragraph,
  Image,
  ImageContainer,
  BannerTitle,
  BannerSubtitle
} from './style';

export default function Home() {
  const [loaded, setLoaded] = useState(false);

  return (
    <Layout>
      <Banner>
        <Video autoPlay muted loop src={video} onLoadedData={() => setLoaded(true)} />

        <Content>
          {loaded ? (
            <>
              <TextContainer>
                <BannerTitle>Puchta Engenharia</BannerTitle>
                <BannerSubtitle>
                  <Translator path={'banner_subtitle'} />
                </BannerSubtitle>
              </TextContainer>
              <ImageContainer>
                <Image src={image} />
              </ImageContainer>
            </>
          ) : (
            <Spin size="large" />
          )}
        </Content>
      </Banner>

      <Title>
        <h2>
          <Translator path={'who_we_are.title'} />
        </h2>
      </Title>

      <TitleBorderBottomContainer>
        <TitleBorderBottom />
      </TitleBorderBottomContainer>

      <AboutUsContainer>
        <Paragraph>
          <Translator path={'who_we_are.text'} />
        </Paragraph>
      </AboutUsContainer>

      <Divider />

      <Services />

      <Divider />

      <Customers />

      <Divider />

      <Partners />
    </Layout>
  );
}
