import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const fadeInUp = keyframes`
  0% { transform: translateY(100%); opacity: 0 }
  100% { transform: translateY(0%); opacity: 1 } 
`;

const zoomIn = keyframes`
  0% { transform: scale(0) }
  100% { transform: scale(1) }
`;

const up_down = keyframes`
  0% { transform: translateY(10px) }
  100% { transform: translateY(-10px) }
`;

export const Banner = styled.div`
  height: calc(80vh - 72px);
  position: relative;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
`;

export const Content = styled.div`
  z-index: 1;
  position: relative;

  width: inherit;
  height: inherit;

  display: flex;
  flex-direction: row;
  gap: 5em;
  align-items: center;
  justify-content: center;
  padding: 5em 15em;

  @media (max-width: 1400px) {
    padding: 5em 6em 5em 10em;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 1.5em;
  }
`;

export const TextContainer = styled.div`
  animation: 2s ${fadeInUp};
`;

export const BannerTitle = styled.h1`
  color: ${(props) => props.theme.banner_text};
  font-size: 3em;
  padding-bottom: 0.5em;

  @media (max-width: 1200px) {
    font-size: 2em;
  }
`;

export const BannerSubtitle = styled.h2`
  color: ${(props) => props.theme.banner_text};
  font-size: 1.6em;
  animation: 2s ${fadeInUp};

  @media (max-width: 1200px) {
    font-size: 1.3em;
  }
`;

export const Title = styled.div`
  padding-top: 1.5em;

  h2 {
    text-align: center;
    font-weight: normal;
  }
`;

export const AboutUsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Paragraph = styled.p`
  font-size: 20px;
  max-width: 1000px;
  padding: 0 1em 1em;
  line-height: 1.5em;
`;

export const TitleBorderBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleBorderBottom = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 1em 0 3em;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1.5s, ${zoomIn} 1.5s;
  width: 100%;
`;

export const Image = styled.img`
  animation: ${up_down} 2s ease-in-out infinite alternate-reverse both;
  width: inherit;
  max-width: 612px;
`;
