import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { closeMenu } from '../../../store/slices/menu';

import { menu } from '../../../configs/menu';

import { Wrapper, LinkContainer, Title, Icon } from './style';

const HeaderMenu = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.menu?.open);

  const handleCloseMenu = () => {
    if (open) dispatch(closeMenu());
  };

  return (
    <Wrapper>
      {menu.map((item, index) => (
        <LinkContainer
          key={index}
          to={`${item.path}#${item.hash}`}
          scroll={(el) => {
            window.scrollTo({
              top: el.offsetTop - 72 - 48,
              behavior: 'smooth'
            });
          }}
          onClick={handleCloseMenu}
        >
          <Icon>{item.icon}</Icon>
          <Title>{item.name}</Title>
        </LinkContainer>
      ))}
    </Wrapper>
  );
};

export default memo(HeaderMenu);
