import styled from 'styled-components';

export const Title = styled.div`
  h2 {
    text-align: center;
    font-weight: normal;
  }
`;

export const Logo = styled.img`
  height: ${(props) => props.height};
  filter: ${(props) => props.theme.logo_filter};
`;

export const TitleBorderBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleBorderBottom = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 1em 0 3em;
`;
