// import { FaBoxes } from "react-icons/fa";
import { /* IoCall */ IoPeople } from 'react-icons/io5';
import { FaGears } from 'react-icons/fa6';
import { AiFillHome } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';

import Translator from '../components/Translator';

export const menu = [
  {
    name: <Translator path="menu.home" />,
    icon: <AiFillHome size={20} />,
    path: '/',
    hash: 'home'
  },
  {
    name: <Translator path="menu.solutions" />,
    icon: <FaGears size={20} />,
    path: '/',
    hash: 'solutions'
  },
  // {
  //   name: <Translator path="menu.products" />,
  //   icon: <FaBoxes size={20} />,
  //   path: "/",
  //   hash: "products",
  // },
  // {
  //   name: <Translator path="menu.services" />,
  //   icon: <FaGears size={20} />,
  //   path: "/",
  //   hash: "services",
  // },
  {
    name: <Translator path="menu.customers" />,
    icon: <IoPeople size={20} />,
    path: '/',
    hash: 'customers'
  },
  {
    name: <Translator path="menu.partners" />,
    icon: <FaHandshake size={20} />,
    path: '/',
    hash: 'partners'
  },
  // {
  //   name: <Translator path="menu.contact" />,
  //   icon: <IoCall size={20} />,
  //   path: '/contact',
  //   hash: 'home'
  // }
];
