import styled from 'styled-components';

import { HashLink } from 'react-router-hash-link';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
`;

export const LinkContainer = styled(HashLink)`
  cursor: pointer;
  text-decoration: none;

  &:link {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:hover {
    color: inherit;
  }

  &:active {
    color: inherit;
  }
`;

export const Title = styled.p`
  padding: 0.25em 0;
  color: ${(props) => props.theme.footer_text};

  &:hover {
    color: ${(props) => props.theme.institutional_hover};
  }
`;
