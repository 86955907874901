import Carousel from 'react-multi-carousel';
import CustomDot from '../CustomDot';
import CustomLeftArrow from '../CustomLeftArrow';
import CustomRightArrow from '../CustomRightArrow';

import { Container } from './style';

import 'react-multi-carousel/lib/styles.css';
import './style.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1400, min: 768 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

export default function CustomCarousel({ children }) {
  return (
    <Container>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        ssr={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        itemClass="item"
        containerClass="container"
        customDot={<CustomDot />}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {children}
      </Carousel>
    </Container>
  );
}
