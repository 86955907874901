import SpainFlag from '../assets/spain.png';
import BrazilFlag from '../assets/brazil.png';
import EuaFlag from '../assets/united-states.png';

import Translator from '../components/Translator';

export const languages = [
  {
    name: <Translator path="language.portuguese" />,
    flag: BrazilFlag,
    abbreviation: 'pt-BR'
  },
  {
    name: <Translator path="language.english" />,
    flag: EuaFlag,
    abbreviation: 'en-US'
  },
  {
    name: <Translator path="language.spanish" />,
    flag: SpainFlag,
    abbreviation: 'es'
  }
];
