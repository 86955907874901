import { useTranslation } from 'react-i18next';

import Translator from '../Translator';

import { Wrapper, Flag } from './style';

import SpainFlag from '../../assets/spain.png';
import BrazilFlag from '../../assets/brazil.png';
import EuaFlag from '../../assets/united-states.png';

export default function LanguageView({ handleClick }) {
  const { t } = useTranslation();

  const selectedLanguage = localStorage.getItem('i18nextLng');

  return (
    <Wrapper onClick={handleClick}>
      <Flag
        src={selectedLanguage === 'es' ? SpainFlag : selectedLanguage === 'en-US' ? EuaFlag : BrazilFlag}
        alt={t('alt.language')}
      />
      <span>
        <Translator
          path={
            selectedLanguage === 'es'
              ? 'language.spanish'
              : selectedLanguage === 'en-US'
              ? 'language.english'
              : 'language.portuguese'
          }
        />
      </span>
    </Wrapper>
  );
}
