export const lightTheme = {
  background: '#F8F8F8',
  header_background: '#FFFFFF',
  footer_background: '#00283A',
  footer_text: '#c3c3c3',
  copyright_background: '#002e42',
  copyright_text: '#BBBBBB',
  divider: '#DBDBDB',
  institutional_hover: '#FFFFFF',
  contact_background: '#FFFFFF',
  contact_border: '#DEDEDE',
  contact_input_placeholder: '#c2c2c2',
  notification_border: '#DEDEDE',
  button_hover_background: '#f0f0f0',
  button_active_background: '#d9d9d9',
  colors: {
    primary: '#153D77',
    text: '#141414'
  },
  logo_filter: '',
  dot_background: '#e2e2e9',
  banner_text: '#ffffff'
};

export const darkTheme = {
  background: '#2c2c2c',
  header_background: '#222222',
  footer_background: '#00283A',
  footer_text: '#c3c3c3',
  copyright_background: '#002e42',
  copyright_text: '#BBBBBB',
  divider: '#DBDBDB',
  institutional_hover: '#FFFFFF',
  contact_background: '#272727',
  contact_border: '#181818',
  contact_input_placeholder: '#808080',
  notification_border: '#DEDEDE',
  button_hover_background: '#202020',
  button_active_background: '#1d1d1d',
  colors: {
    primary: '#7B9AC6',
    text: '#c3c3c3'
  },
  logo_filter: 'grayscale(100%) invert()',
  dot_background: '#CBCBD1',
  banner_text: '#ffffff'
};
