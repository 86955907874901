import styled from 'styled-components';

import { IoMdClose } from 'react-icons/io';
import { GiHamburgerMenu } from 'react-icons/gi';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const Container = styled.div`
  height: 72px;
  background-color: ${(props) => props.theme.header_background};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 7%;
  width: 100%;
  border-bottom: 4px solid ${(props) => props.theme.colors.primary};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (min-width: 1400px) {
    display: none;
  }
`;

export const CompleteLogoContainer = styled.div`
  flex: 1;

  @media (max-width: 1399px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;

  @media (max-width: 768px) {
    justify-content: end;
  }

  @media (min-width: 1400px) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: 48px;
  filter: ${(props) => props.theme.logo_filter};
`;

export const LanguageAndThemeSwitcherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 24px;
  flex: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MediumAndSmallLanguageAndThemeSwitcherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MenuIcon = styled(GiHamburgerMenu)`
  color: ${(props) => props.theme.colors.primary};
`;

export const CloseMenuIcon = styled(IoMdClose)`
  color: ${(props) => props.theme.colors.primary};
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: transparent;
  border: 1px solid transparent;
  height: 35px;
  width: 35px;
  cursor: pointer;
`;

export const HeaderMenuContainer = styled.div`
  flex: 1;

  @media (max-width: 1399px) {
    display: none;
  }
`;

export const MediumAndSmallHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 72px;
  width: 100%;
  background-color: ${(props) => props.theme.header_background};
  border-bottom: 4px solid ${(props) => props.theme.colors.primary};
  padding: 10px 30px;
  z-index: 1000;

  @media (min-width: 1400px) {
    display: none;
  }
`;

export const PositioningDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Divider = styled.span`
  height: 1px;
  background-color: ${(props) => props.theme.divider};
  width: 80%;
`;

export const Title = styled.h3`
  text-align: center;
  padding: 6px 0;
`;
