import styled from 'styled-components';

import { IoIosArrowForward } from 'react-icons/io';

export const Container = styled.button`
  position: absolute;
  right: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

export const RightArrow = styled(IoIosArrowForward)`
  color: ${(props) => props.theme.colors.primary};
`;
