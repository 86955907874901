import { memo } from 'react';

import { menu } from '../../../configs/menu';

import { Wrapper, LinkContainer, Title } from './style';

const FooterMenu = () => {
  return (
    <Wrapper>
      {menu.map((item, index) => (
        <LinkContainer
          key={index}
          to={`${item.path}#${item.hash}`}
          scroll={(el) => {
            window.scrollTo({
              top: el.offsetTop - 72 - 48,
              behavior: 'smooth'
            });
          }}
        >
          <Title>{item.name}</Title>
        </LinkContainer>
      ))}
    </Wrapper>
  );
};

export default memo(FooterMenu);
