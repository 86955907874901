// Customers
import tirolLogo from '../assets/customers/Tirol.png';
import vapzaLogo from '../assets/customers/Vapza.png';
import sigLogo from '../assets/customers/Sig.png';
import dafLogo from '../assets/customers/Daf.png';
import elisLogo from '../assets/customers/Elis.png';
import rofaLogo from '../assets/customers/Rofa.png';
import fevLogo from '../assets/customers/Fev.png';
import aguiaLogo from '../assets/customers/Águia Sistemas.svg';
import morathLogo from '../assets/customers/Morath.png';
import searaLogo from '../assets/customers/Seara.png';
import uniumLogo from '../assets/customers/Unium.png';
import castrolandaLogo from '../assets/customers/Castrolanda.png';

// Partners
import abbLogo from '../assets/partners/ABB.png';
import brLogo from '../assets/partners/B&R.png';
import siemensLogo from '../assets/partners/Siemens.png';
import rockweellLogo from '../assets/partners/Rockwell_Automation.png';

export const PHONE = '+55 42 99116-8200';
export const EMAIL = 'atendimento@puchtaengenharia.com.br';

export const customers = [
  { logo: tirolLogo, height: 60 },
  { logo: vapzaLogo, height: 80 },
  { logo: sigLogo, height: 70 },
  { logo: dafLogo, height: 60 },
  { logo: elisLogo, height: 70 },
  { logo: rofaLogo, height: 90 },
  { logo: fevLogo, height: 60 },
  { logo: aguiaLogo, height: 70 },
  { logo: morathLogo, height: 70 },
  { logo: searaLogo, height: 90 },
  { logo: uniumLogo, height: 80 },
  { logo: castrolandaLogo, height: 50 }
];

export const partners = [
  { logo: abbLogo, height: 70 },
  { logo: brLogo, height: 60 },
  { logo: siemensLogo, height: 80 },
  { logo: rockweellLogo, height: 60 }
];
