import Footer from '../components/Footer';
import Header from '../components/Header';
import WhatsAppFloatButton from '../components/WhatsAppFloatButton';

import { Wrapper, Content } from './style';

export default function Layout({ children }) {
  return (
    <Wrapper>
      <Header />

      <Content>{children}</Content>

      <WhatsAppFloatButton />
      <Footer />
    </Wrapper>
  );
}
