import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  cursor: pointer;

  border-radius: 4px;
  background: transparent;
  border: 1px solid transparent;
  font-size: 1em;
  color: ${(props) => props.theme.colors.text};
`;

export const Flag = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 5px;
`;
