import Layout from '../../layout';
import Translator from '../../components/Translator';

import { Title, Container, Content, UnorderedList, Paragraph, ListItem } from './style';

export default function IndustrialAutomation() {
  return (
    <Layout>
      <Title id="home">
        <Translator path={'industrial_automation.title'} />
      </Title>

      <Container>
        <Content>
          <Paragraph>
            <Translator path={'industrial_automation.text'} />
          </Paragraph>
          <UnorderedList>
            <ListItem>
              <Translator path={'industrial_automation.ul.li_1'} />
            </ListItem>
            <ListItem>
              <Translator path={'industrial_automation.ul.li_2'} />
            </ListItem>
            <ListItem>
              <Translator path={'industrial_automation.ul.li_3'} />
            </ListItem>
            <ListItem>
              <Translator path={'industrial_automation.ul.li_4'} />
            </ListItem>
            <ListItem>
              <Translator path={'industrial_automation.ul.li_5'} />
            </ListItem>
          </UnorderedList>
        </Content>
      </Container>
    </Layout>
  );
}
