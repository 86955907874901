import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from './styles/global';
import { darkTheme, lightTheme } from './styles/theme';

import AppRoutes from './routes';

function App() {
  const type = useSelector((state) => state.theme?.type);

  return (
    <ThemeProvider theme={type === 'dark' ? darkTheme : lightTheme}>
      <GlobalStyle />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
