import styled from 'styled-components';

import { MdDarkMode, MdLightMode } from 'react-icons/md';

export const DarkThemeIcon = styled(MdDarkMode)`
  color: ${(props) => props.theme.colors.primary};
`;

export const LightThemeIcon = styled(MdLightMode)`
  color: ${(props) => props.theme.colors.primary};
`;

export const ThemeSwitcherButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: transparent;
  border: 1px solid transparent;
  height: 35px;
  width: 35px;
  cursor: pointer;
`;
