import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Translator from '../Translator';
import FooterMenu from '../Menu/FooterMenu';

import { PHONE, EMAIL } from '../../constants';
import qualifiedPartner from '../../assets/qualified-partner.png';

import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa6';

import {
  Logo,
  Text,
  Title,
  Wrapper,
  IconContainer,
  CopyrightText,
  ColumnContainer,
  FooterContainer,
  CopyrightContainer,
  QualifiedPartnerTitle,
  QualifiedPartnerContainer
} from './style';

export default function Footer() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FooterContainer>
        <QualifiedPartnerContainer>
          <Logo src={qualifiedPartner} alt={t('qualified_partner')} />
          <QualifiedPartnerTitle>
            <Translator path={'qualified_partner'} />
          </QualifiedPartnerTitle>
        </QualifiedPartnerContainer>
        <div>
          <Title>
            <Translator path={'footer.institutional.title'} />
          </Title>

          <FooterMenu />
        </div>
        <ColumnContainer>
          <div>
            <Title>
              <Translator path="footer.address.title" />
            </Title>
            <Text>
              <Translator path="footer.address.first_part" />
            </Text>
            <Text>
              <Translator path="footer.address.second_part" />
            </Text>
          </div>
          <div>
            <Title>
              <Translator path="footer.opening_hours.title" />
            </Title>
            <Text>
              <Translator path="footer.opening_hours.first_part" />
            </Text>
            <Text>
              <Translator path="footer.opening_hours.second_part" />
            </Text>
          </div>
        </ColumnContainer>
        <ColumnContainer>
          <div>
            <Title>
              <Translator path="footer.contact.title" />
            </Title>
            <Text>
              <Translator path="footer.contact.phone_number" /> {PHONE}
            </Text>
            <Text>
              <Translator path="footer.contact.email" /> {EMAIL}
            </Text>
          </div>
          <IconContainer>
            <Link
              to={`https://api.whatsapp.com/send?phone=${PHONE}&text=${t('message')}&type=phone_number&app_absent=0`}
              target="_blank"
            >
              <FaWhatsapp size={40} color={theme.footer_text} />
            </Link>

            <Link to="https://www.instagram.com/puchta.engenharia" target="_blank">
              <FaInstagram size={40} color={theme.footer_text} />
            </Link>

            <Link to="https://www.linkedin.com/company/puchta-engenharia" target="_blank">
              <FaLinkedin size={40} color={theme.footer_text} />
            </Link>
          </IconContainer>
        </ColumnContainer>
      </FooterContainer>
      <CopyrightContainer>
        <CopyrightText>
          &copy; <Translator path="footer.copyright" />
        </CopyrightText>
      </CopyrightContainer>
    </Wrapper>
  );
}
