import { useState } from 'react';
import { useTheme } from 'styled-components';

import { Modal } from 'antd';
import Translator from '../Translator';
import LanguageView from '../LanguageView';
import LanguageSelector from '../LanguageSelector';

import { ModalTitle } from './style';

import { IoMdClose } from 'react-icons/io';

export default function LanguageSwitcher() {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <LanguageView handleClick={openModal} />
      <Modal
        title={
          <ModalTitle>
            <Translator path={'language.change'} />
          </ModalTitle>
        }
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        styles={{
          body: {
            backgroundColor: theme.background
          },
          content: {
            backgroundColor: theme.background
          },
          header: {
            backgroundColor: theme.background
          }
        }}
        closeIcon={<IoMdClose color={theme.colors.text} size={22} />}
      >
        <LanguageSelector handleClose={closeModal} />
      </Modal>
    </>
  );
}
