import styled from 'styled-components';

import { Radio } from 'antd';

export const CustomRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const Flag = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 5px;
`;

export const Language = styled.span`
  color: ${(props) => props.theme.colors.text};
`;
