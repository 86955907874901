export const enUsTranslations = {
  translations: {
    banner_subtitle: 'Developing intelligent solutions for the industry since 2012',
    qualified_partner: 'B&R Qualified Partner',
    message: 'Hello! I am interested in receiving more information about Puchta Engenharia’s services and products!',
    alt: {
      logo: 'Puchta Engenharia logo',
      language: 'Flag of the selected language',
      flags: {
        pt_BR: "Brazil's flag",
        en_US: "United States's flag",
        es: "Spain's flag"
      }
    },
    customers: {
      title: 'Our customers'
    },
    partners: {
      title: 'Our partners'
    },
    menu: {
      home: 'Home',
      products: 'Products',
      services: 'Services',
      contact: 'Contact',
      solutions: 'Solutions',
      customers: 'Customers',
      partners: 'Partners'
    },
    language: {
      portuguese: 'Portuguese',
      spanish: 'Spanish',
      english: 'English',
      change: 'Choose your language'
    },
    footer: {
      copyright: 'Puchta Engenharia. All rights reserved',
      address: {
        title: 'Address',
        first_part: 'Rua dos Topázios, nº 135, Centro',
        second_part: 'Sala 21, Carambeí-PR'
      },
      opening_hours: {
        title: 'Opening Hours',
        first_part: 'Monday to Friday',
        second_part: 'From 7:30 am to 5:30 pm'
      },
      institutional: {
        title: 'Institutional'
      },
      contact: {
        title: 'Contact',
        phone_number: 'Phone Number:',
        email: 'Email:'
      }
    },
    contact: {
      title: 'Contact us!',
      phone_number: 'Phone Number:',
      email: 'Email:',
      form: {
        name: {
          label: 'Name',
          placeholder: 'Your name',
          warning: 'Enter your name!'
        },
        email: {
          label: 'Email',
          placeholder: 'Your email',
          warning: 'Enter a valid email!'
        },
        msg: {
          label: 'Message',
          placeholder: 'How can we help?',
          warning: 'Enter your message!'
        },
        submit: 'Send'
      },
      notification: {
        success: 'Your message has been sent successfully!',
        error: 'There was a problem sending your message'
      }
    },
    who_we_are: {
      title: 'Who we are?',
      text: 'Puchta Engenharia is an engineering and industrial automation company that operates in the market with a focus on the quality of services offered to the customer, with empathy towards customers as the main pillar, as well as offering a product of high technical quality. Today, we have a highly qualified team to develop and execute our services. We have been in the market for eleven years developing intelligent solutions for the industry, always focusing on our pillars: excellence, responsibility and empathy towards the customer.'
    },
    services: {
      title: 'Solutions',
      card_1: {
        title: 'Mwise 2.0',
        text: 'Mwise is a specialist system for managing machines and production lines that allows data management to put you in control.',
        link: 'Know more'
      },
      card_2: {
        title: 'Development of Industrial Automation Projects',
        text: 'From design to final execution, Puchta Engenharia has qualified professionals and offers the highest technologies on the market.',
        link: 'Know more'
      }
    },
    industrial_automation: {
      title: 'Development of Industrial Automation Projects',
      text: 'From design to final execution, Puchta Engenharia has qualified professionals and offers the highest technologies available on the market. We take care of specific issues or complete projects; We meet the integral needs of your company at all levels of production.',
      ul: {
        li_1: 'Consultancy for product development and formulation',
        li_2: 'Component specification',
        li_3: 'Development',
        li_4: 'Execution',
        li_5: 'Training for the areas involved'
      }
    },
    mwise: {
      title: 'Mwise 2.0',
      first_paragraph:
        'Mwise is a specialist system for managing machines and production lines, which allows data management to put you in control.',
      second_paragraph:
        'Developed in a web-based environment, 100% online, which allows it to be used on all platforms: computers, tablets, smartphones. Everything with maximum security, running on a local server or in the cloud.',
      third_paragraph:
        'Furthermore, MWISE was developed using management module technology, covering: Line Monitoring System (LMS), Product Lifecycle Management (PLM), Business Intelligence (BI) and Process Traceability.',
      fourth_paragraph:
        'The system allows you to monitor process parameters and the most important control points of two machines and production lines.',
      fifth_paragraph: 'Manage your line data and obtain the following benefits:',
      ul: {
        li_1: 'Reduce human errors with fact-based decisions;',
        li_2: 'Ensure consistent operational procedures with reliable information and data management;',
        li_3: 'Reduce required labor: less time needed for calculations and data analysis;',
        li_4: 'Reduce waste and products outside of specifications;',
        li_5: 'Increase OEE (Overall Equipment Efficiency) with optimized use of lines and equipment.'
      }
    }
  }
};
