import Translator from '../Translator';
import CustomCarousel from '../CustomCarousel';

import { partners } from '../../constants';

import { Title, Logo, TitleBorderBottomContainer, TitleBorderBottom } from './style';

export default function Partners() {
  return (
    <>
      <Title id="partners">
        <h2>
          <Translator path={'partners.title'} />
        </h2>
      </Title>

      <TitleBorderBottomContainer>
        <TitleBorderBottom />
      </TitleBorderBottomContainer>

      <CustomCarousel>
        {partners.map((partner, index) => (
          <Logo key={index} src={partner.logo} height={partner.height} />
        ))}
      </CustomCarousel>
    </>
  );
}
