import styled from 'styled-components';

import { IoIosArrowBack } from 'react-icons/io';

export const Container = styled.button`
  position: absolute;
  left: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

export const LeftArrow = styled(IoIosArrowBack)`
  color: ${(props) => props.theme.colors.primary};
`;
