import { useTheme } from 'styled-components';

import Translator from '../Translator';

import mes from '../../assets/discrete-manufacturing.jpg';
import automation from '../../assets/IA.png';

import { MdChevronRight } from 'react-icons/md';

import {
  Title,
  TitleBorderBottomContainer,
  TitleBorderBottom,
  Card,
  CardContainer,
  Image,
  CardTitle,
  LinkTo,
  CardDescription
} from './style';

export default function Services() {
  const theme = useTheme();

  return (
    <>
      <Title id="solutions">
        <h2>
          <Translator path={'services.title'} />
        </h2>
      </Title>

      <TitleBorderBottomContainer>
        <TitleBorderBottom />
      </TitleBorderBottomContainer>

      <CardContainer>
        <Card>
          <Image src={mes} />

          <CardTitle>
            <Translator path={'services.card_1.title'} />
          </CardTitle>

          <CardDescription>
            <Translator path={'services.card_1.text'} />
          </CardDescription>

          <LinkTo
            to={'/mwise#home'}
            scroll={(el) => {
              window.scrollTo({
                top: el.offsetTop - 72 - 48,
                behavior: 'smooth'
              });
            }}
          >
            <Translator path={'services.card_1.link'} />
            <MdChevronRight size={18} color={theme.colors.text} />
          </LinkTo>
        </Card>

        <Card>
          <Image src={automation} />

          <CardTitle>
            <Translator path={'services.card_2.title'} />
          </CardTitle>

          <CardDescription>
            <Translator path={'services.card_2.text'} />
          </CardDescription>

          <LinkTo
            to={'/industrial-automation#home'}
            scroll={(el) => {
              window.scrollTo({
                top: el.offsetTop - 72 - 48,
                behavior: 'smooth'
              });
            }}
          >
            <Translator path={'services.card_2.link'} />
            <MdChevronRight size={18} color={theme.colors.text} />
          </LinkTo>
        </Card>
      </CardContainer>
    </>
  );
}
