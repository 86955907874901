import Layout from '../../layout';
import Translator from '../../components/Translator';

import { Title, Container, Content, UnorderedList, Paragraph, ListItem } from './style';

export default function Mwise() {
  return (
    <Layout>
      <Container>
        <Content>
          <Title id="home">
            <Translator path={'mwise.title'} />
          </Title>

          <Paragraph>
            <Translator path={'mwise.first_paragraph'} />
          </Paragraph>

          <Paragraph>
            <Translator path={'mwise.second_paragraph'} />
          </Paragraph>

          <Paragraph>
            <Translator path={'mwise.third_paragraph'} />
          </Paragraph>

          <Paragraph>
            <Translator path={'mwise.fourth_paragraph'} />
          </Paragraph>

          <Paragraph>
            <Translator path={'mwise.fifth_paragraph'} />
          </Paragraph>

          <UnorderedList>
            <ListItem>
              <Translator path={'mwise.ul.li_1'} />
            </ListItem>
            <ListItem>
              <Translator path={'mwise.ul.li_2'} />
            </ListItem>
            <ListItem>
              <Translator path={'mwise.ul.li_3'} />
            </ListItem>
            <ListItem>
              <Translator path={'mwise.ul.li_4'} />
            </ListItem>
            <ListItem>
              <Translator path={'mwise.ul.li_5'} />
            </ListItem>
          </UnorderedList>
        </Content>
      </Container>
    </Layout>
  );
}
