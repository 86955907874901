import { createSlice } from '@reduxjs/toolkit';

const menu = createSlice({
  name: 'menu',
  initialState: {
    open: false
  },
  reducers: {
    closeMenu(state) {
      state.open = false;
    },
    toggleMenu(state) {
      state.open = !state.open;
    }
  }
});

export default menu.reducer;

export const { closeMenu, toggleMenu } = menu.actions;
