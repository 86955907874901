import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Home from '../pages/Home';
import Mwise from '../pages/Mwise';
// import Contact from '../pages/Contact';
import IndustrialAutomation from '../pages/IndustrialAutomation';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/mwise" element={<Mwise />} />
        <Route path="/industrial-automation" element={<IndustrialAutomation />} />
      </Routes>
    </BrowserRouter>
  );
}
