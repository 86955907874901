import styled from 'styled-components';

export const Dot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-right: 6px;
  background: ${(props) => (props.$active ? props.theme.colors.primary : props.theme.dot_background)};
`;
