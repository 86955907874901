export const esTranslations = {
  translations: {
    banner_subtitle: 'Desde 2012 desarrollando soluciones inteligentes para la industria',
    qualified_partner: 'Socio Cualificado de B&R',
    message:
      '¡Hola! ¡Estoy interesado en recibir más información sobre los servicios y productos de Puchta Engenharia!',
    alt: {
      logo: 'Logotipo de Puchta Engenharia',
      language: 'Bandera del idioma seleccionado',
      flags: {
        pt_BR: 'Bandera de Brasil',
        en_US: 'Bandera de los Estados Unidos',
        es: 'Bandera española'
      }
    },
    customers: {
      title: 'Nuestros clientes'
    },
    partners: {
      title: 'Nuestros compañeros'
    },
    menu: {
      home: 'Hogar',
      products: 'Productos',
      services: 'Servicios',
      contact: 'Contacto',
      solutions: 'Soluciones',
      customers: 'Clientes',
      partners: 'Socios'
    },
    language: {
      portuguese: 'Portugués',
      spanish: 'Español',
      english: 'Inglés',
      change: 'Seleccione su idioma'
    },
    footer: {
      copyright: 'Puchta Engenharia. Todos los derechos reservados',
      address: {
        title: 'Dirección',
        first_part: 'Rua dos Topázios, nº 135, Centro',
        second_part: 'Sala 21, Carambeí-PR'
      },
      opening_hours: {
        title: 'Horas de Oficina',
        first_part: 'Lunes a Viernes',
        second_part: 'De 7:30 a 17:30'
      },
      institutional: {
        title: 'Institucional'
      },
      contact: {
        title: 'Contacto',
        phone_number: 'Número de Teléfono:',
        email: 'Email:'
      }
    },
    contact: {
      title: '¡Contáctenos!',
      phone_number: 'Número de Teléfono:',
      email: 'Email:',
      form: {
        name: {
          label: 'Nombre',
          placeholder: 'Su nombre',
          warning: '¡Ingrese su nombre!'
        },
        email: {
          label: 'Email',
          placeholder: 'Su email',
          warning: '¡Ingrese un email valido!'
        },
        msg: {
          label: 'Mensaje',
          placeholder: '¿Cómo podemos ayudar?',
          warning: '¡Ingrese su mensaje!'
        },
        submit: 'Mandar'
      },
      notification: {
        success: '¡Su mensaje ha sido enviado con éxito!',
        error: 'Hubo un problema al enviar tu mensaje'
      }
    },
    who_we_are: {
      title: '¿Quienes somos?',
      text: 'Puchta Engenharia es una empresa de ingeniería y automatización industrial que actúa en el mercado con enfoque en la calidad de los servicios ofrecidos al cliente, teniendo como pilar principal la empatía hacia los clientes, además de ofrecer un producto de alta calidad técnica. Hoy contamos con un equipo altamente calificado para desarrollar y ejecutar nuestros servicios. Llevamos once años en el mercado desarrollando soluciones inteligentes para la industria, siempre enfocados en nuestros pilares: excelencia, responsabilidad y empatía hacia el cliente.'
    },
    services: {
      title: 'Soluciones',
      card_1: {
        title: 'Mwise 2.0',
        text: 'Mwise es un sistema especializado de gestión de máquinas y líneas de producción que permite que la gestión de datos le dé el control.',
        link: 'Sepa mas'
      },
      card_2: {
        title: 'Desarrollo de Proyectos de Automatización Industrial',
        text: 'Desde el diseño hasta la ejecución final, Puchta Engenharia cuenta con profesionales calificados y ofrece las más altas tecnologías del mercado.',
        link: 'Sepa mas'
      }
    },
    industrial_automation: {
      title: 'Desarrollo de Proyectos de Automatización Industrial',
      text: 'Desde el diseño hasta la ejecución final, Puchta Engenharia cuenta con profesionales calificados y ofrece las más altas tecnologías disponibles en el mercado. Nos ocupamos de temas puntuales o proyectos completos; Atendemos las necesidades integrales de su empresa en todos los niveles de producción.',
      ul: {
        li_1: 'Consultoría para el desarrollo y formulación de productos',
        li_2: 'Especificación de componentes',
        li_3: 'Desarrollo',
        li_4: 'Ejecución',
        li_5: 'Capacitación para las áreas involucradas'
      }
    },
    mwise: {
      title: 'Mwise 2.0',
      first_paragraph:
        'Mwise es un sistema especializado para gestionar máquinas y líneas de producción, que permite que la gestión de datos le dé el control.',
      second_paragraph:
        'Desarrollado en un entorno web, 100% online, que permite su uso en todas las plataformas: ordenadores, tablets, smartphones. Todo con la máxima seguridad, ejecutándose en un servidor local o en la nube.',
      third_paragraph:
        'Además, MWISE se desarrolló utilizando tecnología de módulos de gestión, que cubren: Life Monitoring System (LMS), Product Lifecycle Management (PLM), Business Intelligence (BI) y trazabilidad de procesos.',
      fourth_paragraph:
        'El sistema permite monitorear los parámetros del proceso y los puntos de control más importantes de dos máquinas y líneas de producción.',
      fifth_paragraph: 'Administra los datos de tu línea y obtén los siguientes beneficios:',
      ul: {
        li_1: 'Reducir los errores humanos con decisiones basadas en hechos;',
        li_2: 'Garantizar procedimientos operativos coherentes con información y gestión de datos fiables;',
        li_3: 'Reducir la mano de obra requerida: menos tiempo necesario para cálculos y análisis de datos;',
        li_4: 'Reducir los residuos y los productos fuera de las especificaciones;',
        li_5: 'Aumente la OEE (Eficiencia General del Equipo) con un uso optimizado de líneas y equipos.'
      }
    }
  }
};
