import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em 0;
`;

export const Line = styled.span`
  height: 1px;
  width: 80%;
  background-color: ${(props) => props.theme.contact_border};
`;
