import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: auto;
`;

export const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.footer_background};
  display: flex;
  justify-content: center;
  padding: 1em;
  flex-direction: row;
  gap: 10%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const FooterText = styled.div`
  color: ${(props) => props.theme.footer_text};
`;

export const CopyrightContainer = styled.div`
  background-color: ${(props) => props.theme.copyright_background};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const CopyrightText = styled.div`
  color: ${(props) => props.theme.copyright_text};
`;

export const Title = styled.h3`
  color: ${(props) => props.theme.copyright_text};
  padding-bottom: 6px;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.copyright_text};
  padding: 0.25em 0;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media (max-width: 1000px) {
    padding-top: 2em;
    gap: 2em;
  }
`;

export const Icon = styled.div`
  color: ${(props) => props.theme.footer_text};
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

export const Logo = styled.img`
  height: 100px;
  width: 150px;
`;

export const QualifiedPartnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media (max-width: 1000px) {
    padding-bottom: 2em;
  }
`;

export const QualifiedPartnerTitle = styled.h3`
  color: ${(props) => props.theme.copyright_text};
  padding-top: 0.5em;
`;
