import { useTranslation } from 'react-i18next';

import { FaWhatsapp } from 'react-icons/fa';

import { PHONE } from '../../constants';

import { WhatsAppLink } from './style';

export default function WhatsAppFloatButton() {
  const { t } = useTranslation();

  return (
    <WhatsAppLink
      href={`https://api.whatsapp.com/send?phone=${PHONE}&text=${t('message')}&type=phone_number&app_absent=0`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp size={40} />
    </WhatsAppLink>
  );
}
