import styled from 'styled-components';

export const Title = styled.h1`
  text-align: center;
  padding: 1em;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 1000px;
  padding: 0 1em 1em;
`;

export const UnorderedList = styled.ul`
  padding-left: 1em;
  font-size: 20px;
  line-height: 1.5em;
`;

export const Paragraph = styled.p`
  font-size: 20px;
  line-height: 1.5em;
  padding-bottom: 1em;
`;

export const ListItem = styled.li`
  font-size: 20px;
  line-height: 1.5em;
`;
