import { createSlice } from '@reduxjs/toolkit';

const checkSystemTheme = () => {
  const prefersColorScheme = window.matchMedia('(prefers-color-scheme: dark)');

  if (prefersColorScheme.matches) {
    return 'dark';
  } else {
    return 'light';
  }
};

const theme = createSlice({
  name: 'theme',
  initialState: {
    type: checkSystemTheme()
  },
  reducers: {
    lightTheme(state) {
      state.type = 'light';
    },
    darkTheme(state) {
      state.type = 'dark';
    }
  }
});

export default theme.reducer;

export const { darkTheme, lightTheme } = theme.actions;
