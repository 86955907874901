import styled from 'styled-components';

export const WhatsAppLink = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    bottom: 20px;
    right: 20px;
  }
`;
