import styled from 'styled-components';

// import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export const Title = styled.div`
  /* margin: 1.5em; */

  h2 {
    text-align: center;
    font-weight: normal;
  }
`;

export const TitleBorderBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleBorderBottom = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 1em 0 3em;
`;

export const Card = styled.div`
  max-width: 300px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
`;

export const Image = styled.div`
  background-image: url(${(props) => props.src});
  width: 300px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
`;

export const CardTitle = styled.h2`
  padding: 0.5em 0;
`;

export const CardDescription = styled.p`
  padding-bottom: 12px;
`;

export const LinkTo = styled(HashLink)`
  padding: 0.5em 0;
  text-decoration: none;
  display: inline-flex;
  gap: 0.5em;
  align-items: center;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:link {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:hover {
    color: inherit;
    gap: 1em;
  }

  &:active {
    color: inherit;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 100%;
    bottom: -1px;
    background: ${(props) => props.theme.colors.primary};
    height: 2px;
    transition-property: left right;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
  }

  &:hover:after {
    right: 0;
  }

  &:focus:after {
    right: 0;
  }

  &:active:after {
    right: 0;
  }
`;
