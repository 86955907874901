import { configureStore, combineReducers } from '@reduxjs/toolkit';

import menu from './slices/menu';
import theme from './slices/theme';

const reducer = combineReducers({ theme, menu });

const store = configureStore({ reducer });

export default store;
