import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toggleMenu, closeMenu } from '../../store/slices/menu';

import Translator from '../Translator';
import LanguageView from '../LanguageView';
import HeaderMenu from '../Menu/HeaderMenu';
import ThemeSwitcher from '../ThemeSwitcher';
import LanguageSwitcher from '../LanguageSwitcher';
import LanguageSelector from '../LanguageSelector';

import logo from '../../assets/logoPE.png';
import completeLogo from '../../assets/completeLogoPE.png';

import {
  Logo,
  Title,
  Wrapper,
  Divider,
  MenuIcon,
  Container,
  MenuButton,
  CloseMenuIcon,
  IconContainer,
  LogoContainer,
  PositioningDivider,
  HeaderMenuContainer,
  CompleteLogoContainer,
  MediumAndSmallHeaderContainer,
  LanguageAndThemeSwitcherContainer,
  MediumAndSmallLanguageAndThemeSwitcherContainer
} from './style';

export default function Header() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const open = useSelector((state) => state.menu?.open);

  const [openLanguageSwitcher, setOpenLanguageSwitcher] = useState(false);

  const ref = useRef();

  const handleCloseLanguageSwitcher = () => {
    setOpenLanguageSwitcher(false);
  };

  const handleChangeLanguageSwitcher = () => {
    setOpenLanguageSwitcher(!openLanguageSwitcher);
  };

  useEffect(() => {
    const handler = (event) => {
      if (!ref.current.contains(event.target)) {
        dispatch(closeMenu());
      }
    };

    document.addEventListener('mousedown', handler);

    return () => document.removeEventListener('mousedown', handler);
  });

  return (
    <Wrapper ref={ref}>
      <Container>
        <CompleteLogoContainer>
          <Logo src={completeLogo} alt={t('alt.logo')} />
        </CompleteLogoContainer>

        <IconContainer>
          <MenuButton onClick={() => dispatch(toggleMenu())}>
            {open ? <CloseMenuIcon size={30} /> : <MenuIcon size={30} />}
          </MenuButton>
        </IconContainer>

        <HeaderMenuContainer>
          <HeaderMenu />
        </HeaderMenuContainer>

        <LogoContainer>
          <Logo src={logo} alt={t('alt.logo')} />
        </LogoContainer>

        <LanguageAndThemeSwitcherContainer>
          <ThemeSwitcher />
          <LanguageSwitcher />
        </LanguageAndThemeSwitcherContainer>
      </Container>

      {open && (
        <MediumAndSmallHeaderContainer>
          <HeaderMenu />

          <PositioningDivider>
            <Divider />
          </PositioningDivider>

          <MediumAndSmallLanguageAndThemeSwitcherContainer>
            <ThemeSwitcher />
            <LanguageView handleClick={handleChangeLanguageSwitcher} />
          </MediumAndSmallLanguageAndThemeSwitcherContainer>

          {openLanguageSwitcher && (
            <>
              <PositioningDivider>
                <Divider />
              </PositioningDivider>

              <Title>
                <Translator path={'language.change'} />
              </Title>

              <LanguageSelector handleClose={handleCloseLanguageSwitcher} />
            </>
          )}
        </MediumAndSmallHeaderContainer>
      )}
    </Wrapper>
  );
}
